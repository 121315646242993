import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FlightNumberAirportsResponse } from '../models/flight-number-airports-response';
import { DateTime } from 'luxon';
import { P } from '@angular/cdk/keycodes';
import { IssueFile } from '../models/issue-file';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url: string = environment.apiUrl;
  private addAttachmentSubject = new Subject<boolean>();
  private flightDetailsSubject = new ReplaySubject<{
    flightNumber: string;
    origin: string;
    destination: string;
    originDate: DateTime | null;
  }>(1);

  constructor(private httpClient: HttpClient) {}

  getFlightNumberAirports(): Observable<FlightNumberAirportsResponse> {
    return this.httpClient.get<FlightNumberAirportsResponse>(
      `${this.url}/airport/flightnumberairports`
    );
  }


  setIsAttachmentAdded(value: boolean) {
    this.addAttachmentSubject.next(value);
  }

  getIsAttachmentAdded(): Observable<boolean> {
    return this.addAttachmentSubject.asObservable();
  }

  setFlightDetails(value: {
    flightNumber: string;
    origin: string;
    destination: string;
    originDate: DateTime | null;
  }) {
    this.flightDetailsSubject.next(value);
  }

  getFlightDetails(): Observable<{
    flightNumber: string;
    origin: string;
    destination: string;
    originDate: DateTime | null;
  }> {
    return this.flightDetailsSubject.asObservable();
  }

  createFlightReport(request: any, guestFormAttachments: File[], issueAttachments: IssueFile[]): Observable<any> {
    const formData = new FormData();

    formData.append('request', JSON.stringify(request));
    guestFormAttachments.forEach((guestFormAttachment) => formData.append('guestFormAttachments', guestFormAttachment));

    issueAttachments.forEach((issueAttachment) => {
      formData.append(`issueAttachments[${issueAttachment.issueTemporaryKey}]`, issueAttachment.file);
    })

    return this.httpClient.post(`${this.url}/report/v2`, formData);
  }
}
