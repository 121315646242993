import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar
  ) {}

  notifyOnUpdate(): void {
    this.swUpdate.versionUpdates.subscribe((event) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          console.log('Update available: ' + event.version.hash);
          this.snackbar.open(`Downloading update...`, undefined, {
            duration: 10000
          });
          break;
        case 'VERSION_READY':
          console.log(
            'Update downloaded: ' +
              event.currentVersion +
              ' => ' +
              event.latestVersion
          );
          const snack = this.snackbar.open(`Update downloaded!`, `Install`);
          snack
            .onAction()
            .pipe(switchMap(() => this.swUpdate.activateUpdate()))
            .subscribe(() => window.location.reload());
          break;
        default:
          break;
      }
    });
  }
}
