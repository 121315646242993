import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject, filter, map, shareReplay, takeUntil } from 'rxjs';
import { UpdateService } from './shared/services/update.service';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { OfflineMsalInterceptor } from './auth';
import { environment } from 'src/environments/environment';
import { CacheService } from './shared/services/cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private breakpointObserver: BreakpointObserver,
    private updateService: UpdateService,
    private router: Router,
    private connectionService: ConnectionService,
    private cacheService: CacheService
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(
        () => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        },
        (err) => {
          console.error(err);
        }
      );

      this.connectionService.monitor({
        enableHeartbeat: true,
        heartbeatInterval: 10000,
        heartbeatRetryInterval: 5000,
        heartbeatUrl: environment.apiUrl + '/healthcheck/internetcheck',
        requestMethod: 'get'
      }).subscribe({
        next: (newState: ConnectionState) => {
          console.log('Connection state changed: ', newState);
          OfflineMsalInterceptor.connectionState = newState; 
        }
      });
    this.cacheService.loadAndCacheDictionaries();
    this.updateService.notifyOnUpdate();
  }

  setLoginDisplay() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    activeAccount = this.authService.instance.getActiveAccount();

    const isReporter =
      activeAccount?.idTokenClaims?.roles?.includes('Reporter') ?? false;
    const isReader =
      activeAccount?.idTokenClaims?.roles?.includes('Reader') ?? false;
    const isInstructor =
      activeAccount?.idTokenClaims?.roles?.includes('Instructor') ?? false;
    const isAllReportsReader =
      activeAccount?.idTokenClaims?.roles?.includes('AllReportsReader') ??
      false;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (isReporter && !isReader && !isInstructor && !isAllReportsReader) {
          if (
            event.url === '/report-form' ||
            event.url === '/my-reports' ||
            event.url.startsWith('/my-reports/report/')
          ) {
            return;
          }
          this.router.navigate(['/my-reports']);
        } else if (
          !isReporter &&
          isReader &&
          !isInstructor &&
          !isAllReportsReader
        ) {
          if (event.url.startsWith('/admin/report/')) {
            return;
          }
          this.router.navigate(['/admin']);
        }
      });
  }

  logout(): void {
    this.authService.logoutRedirect();
  }
}
